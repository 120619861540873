<template>
  <div>
    <a-card title="设备类型">
      <a-row :gutter="[12, 24]">
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchForm.search" placeholder="编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>

        <a-col :span="24" style="width: 160px; float: right">
          <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true">新增设备类型</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          >
            <template slot="is_active" slot-scope="value">
              <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
            </template>
            <template slot="action" slot-scope="value, item">
              <TableAction :item="item" @update="update" @destroy="destroy" @refresh="list" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <CreateFormModal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
  </div>
</template>

<script>
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { workshopList } from "@/api/production";

export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "编号",
          dataIndex: "number",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "启用状态",
          dataIndex: "is_active",
          scopedSlots: { customRender: "is_active" },
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [
        {
          id: 1,
          number: "M_TYPE_001",
          name: "组装机",
          is_active: true,
        },
        {
          id: 2,
          number: "M_TYPE_002",
          name: "包装机",
          is_active: true,
        },
      ],

      createModalVisible: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      // this.dataLoading = true;
      // workshopList(this.searchForm)
      //   .then((data) => {
      //     this.pagination.total = data.count;
      //     this.dataItems = data.results;
      //   })
      //   .finally(() => {
      //     this.dataLoading = false;
      //   });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
